import {
	request
} from "./request";
import qs from "qs";

export function getOfficialProducts(obj) { 
	return request({
		url: "official/getOfficialProducts",
		method: "post",
		data: qs.stringify(obj)
	});
}
export function getOfficialTopic(obj) { 
	return request({
		url: "official/getOfficialTopic",
		method: "post",
		data: qs.stringify(obj)
	});
}

export function getTopicAround(obj) { 
	return request({
		url: "official/getTopicAround",
		method: "post",
		data: qs.stringify(obj)
	});
} 

export function getOfficialImgByType(obj) { 
	return request({
		url: "official/getOfficialImgByType",
		method: "post",
		data: qs.stringify(obj)
	});
}