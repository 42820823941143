<template>
	<div id="app" v-cloak>
		<div class="back-ccc">
			<div>
				<el-breadcrumb separator="/">
					<el-breadcrumb-item>首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/health' }">走进诚赉健康</el-breadcrumb-item>
					<el-breadcrumb-item>健康专栏</el-breadcrumb-item>
					<el-breadcrumb-item>{{article.topic_name}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="content">
			<div class="title">{{article.topic_name}}</div>
			<div class="date">{{article.topic_date.split(' ')[0]}}<span>来源：值班长运营团队</span></div>
			<div v-html="article.content" class="model"></div>
			<div class="between" v-if="info.length>1">
				<div v-for="(item,index) in info" @click="infoText(index)">{{index==0?'上一篇：':'下一篇：'}}{{item.topic_name}}
				</div>
			</div>
			<!-- <div class="between-min" v-if="info.length>1">
				<div v-for="(item,index) in info" @click="infoText(index)">{{index==0?'上一篇：':'下一篇：'}}
				</div>
			</div> -->
			<div class="between-res" v-if="info.length==1">
				<div v-for="(item,index) in info" @click="infoText(index)">下一篇：{{item.topic_name}}</div>
			</div>
			<!-- <div class="between-res-min" v-if="info.length==1">
				<div v-for="(item,index) in info" @click="infoText(index)">下一篇</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	import {
		getTopicAround
	} from '../../api/goods.js';
	export default {
		data() {
			return {
				article: '',
				info: [],
				rank: 0
			}
		},
		methods: {
			indexData() {
				sessionStorage.navStatus = 0
			},
			infoText(index) {
				this.article = this.info[index];
				if (this.info.length > 1) {
					if (index == 0) {
						this.rank--;
						this.getTopicAround(this.rank);
						sessionStorage.article = JSON.stringify(this.info[0])
					} else if (index == 1) {
						this.rank++;
						this.getTopicAround(this.rank);
						sessionStorage.article = JSON.stringify(this.info[1])
					}

				} else {
					this.rank++;
					this.getTopicAround(this.rank);
					sessionStorage.article = JSON.stringify(this.info[0])
				}
				sessionStorage.rank = this.rank;
				document.getElementById("app").scrollIntoView();
			},
			getTopicAround(rank) {
				getTopicAround({
					rank: rank
				}).then(res => {
					this.info = res;
				})
			}
		},
		created() {
			document.getElementById("app").scrollIntoView();
			this.rank = sessionStorage.rank;
			this.article = JSON.parse(sessionStorage.article);
			this.getTopicAround(this.rank)
		}
	}
</script>

<style scoped="scoped">
	.between-res-min,.between-min{
		display: none;
	}
	.between-res-min>div{
		display: flex;
		flex-flow: row-reverse;
		font-size: 12px;
		color: #666;
		padding-bottom: 12px;
		padding-right: 12px;
	}
	[v-cloak] {
		display: none;
	}

	.between-res {
		display: flex;
		flex-flow: row-reverse;
		padding-top: 30px;
		border-top: 1px solid #dcdcdc;
		padding-bottom: 100px;
		font-size: 16px;
		color: #999;
	}

	.between div,
	.between-res div {
		cursor: pointer;
		box-sizing: border-box;
		height: 22px;
	}

	.between-res div:hover,
	.between div:hover {
		color: #1CA167;
		border-bottom: 1px solid #1CA167;
		box-sizing: border-box;
	}

	.between {
		display: flex;
		justify-content: space-between;
		padding-top: 30px;
		border-top: 1px solid #dcdcdc;
		padding-bottom: 100px;
		font-size: 16px;
		color: #999;
	}

	.model {
		position: relative;
	}

	.model::after {
		content: '';
		position: absolute;
		width: 100px;
		right: 0;
		top: 0;
		height: 60px;
		background-color: #fff;
	}

	.date {
		font-size: 14px;
		color: #999;
		padding-bottom: 30px;
		border-bottom: 1px solid #dcdcdc;
	}

	.title {
		padding-top: 60px;
		padding-bottom: 24px;
		font-size: 36px;
		font-weight: bold;
		color: #333;
	}

	.content {
		position: relative;
		width: 1280px;
		left: 50%;
		margin-left: -640px;
	}

	.back-ccc {
		position: relative;
		height: 60px;
		width: 100%;
		background-color: #dcdcdc;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.back-ccc>div {
		position: absolute;
		width: 1280px;
		left: 50%;
		margin-left: -640px;
	}
	@media (max-width:500px) {
		.back-ccc{
			display: none;
		}
		.content{
			width: 100%;
			left: 0;
			margin-left: 0;
			box-sizing: border-box;
			padding: 0 12px;
			border-top: 1px solid #dcdcdc;
		}
		.title{
			font-size: 16px;
			padding-top: 16px;
			padding-bottom: 12px;
		}
		.date{
			padding-bottom: 0;
			border-bottom: 0px;
		}
		.model::after {
			width: 0;
		}
		.between,.between-res{
			display: none;
		}
		.between-min,.between-res-min{
			display: block;
		}
	}
</style>
