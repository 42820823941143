<template>
	<div id="app" v-cloak>
		<div class="index_banner big" id="index_banner_top">
			<img v-if="banner.officialImgName" :src="picurl + 'official/' +banner.officialImgName" alt="">
			<img v-else src="../../../public/images/index_banner_top.png" alt="">
		</div>
		<div class="index_banner small">
			<img src="../../../public/images/index-small.jpg" alt="">
		</div>
		<div class="index_banner" id="index_banner_btm">
			<img src="../../../public/images/index_banner_btm.png" alt="">
		</div>
		<!-- <div class="download">
			<img src="../../../public/images/app_download.png" alt="">
		</div> -->
		<div class="doing" @click="doingData">
			<!-- <i :class="status?'el-icon-arrow-down':'el-icon-arrow-up'"></i> -->
			<img :src="status?'images/btm-icon.png':'images/top-icon.png'" alt="">
		</div>
		
	</div>
</template>

<script>
	import {
		picurl
	} from '../../api/request.js';
	import {
		getOfficialImgByType
	} from "../../api/goods.js";
	export default {
		data() {
			return {
				picurl,
				status: true,
				banner: ''
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll)
		},
		methods: {
			getOfficialImgByType() {
				var type = '0' + '1';
				getOfficialImgByType({
					type: 0
				}).then(res => {
					this.banner = res[0];
				})
			},
			handleScroll() {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (scrollTop >= 0 && scrollTop < 200) {
					this.status = true
				} else {
					this.status = false
				}
			},
			doingData() {
				this.status = !this.status;
				if (this.status) {
					var timer = setInterval(function() {
						let osTop = document.documentElement.scrollTop || document.body.scrollTop;
						let ispeed = -10;
						document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
						if (osTop == 0) {
							clearInterval(timer);
						}
					}, 1)

				} else {
					var timer = setInterval(function() {
						let osTop = document.documentElement.scrollTop || document.body.scrollTop;
						let ispeed = 10;
						document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
						if (osTop >= 700) {
							clearInterval(timer);
							document.getElementById("index_banner_btm").scrollIntoView();
						}
					}, 1)
				}
			},
		},
		created() {
			document.getElementById("app").scrollIntoView();
			this.getOfficialImgByType();
		}
	}
</script>

<style scoped="scoped">
	[v-cloak] {
		display: none;
	}
	
	.tel,
	.doing {
		position: fixed;
		right: 50px;
		bottom: 300px;
		background-color: #fff;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: rgba(0, 0, 0, 0.6);
		color: #fff;
		font-size: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2000;
	}

	

	.doing img {
		width: 70%;
	}

	.download {
		width: 200px;
		height: 220px;
		position: fixed;
		right: 0;
		top: 586px;
		box-shadow: 0px 0px 9px 1px #414141;
		z-index: 998;
		border-radius: 8px;
		overflow: hidden;
	}

	.download img {
		width: 100%;
		height: 100%;
	}

	.index_banner {
		width: 100%;
	}

	.index_banner img {
		width: 100%;
		display: block;
	}

	/deep/ .el-tooltip__popper.is-dark {
		font-size: 24px !important;
	}
	.small{
		display: none;
	}
	@media (max-width: 500px) {
		.small{
			display: block;
		}
		.big{
			display: none;
		}
		.doing,
		.tel {
			display: none;
		}
	}
</style>
