<template>
	<div id="app" v-cloak>
		<div class="banner big">
			<img v-if="banner.officialImgName" :src="picurl + 'official/' +banner.officialImgName" alt="">
			<img v-else src="../../../public/images/goods.png" alt="">
		</div>
		<div class="banner small">
			<img src="../../../public/images/goods-small.jpg" alt="">
		</div>
		<div class="content">
			<div class="title">保健食品</div>
			<div class="goods">
				<div class="item" v-for="(goods,index) in goodsList" @click="detailData(goods)">
					<img :src="picurl + 'product/product_' + goods.product_id + '/' +goods.product_pic" alt="">
					<div class="item-name">
						<div>{{goods.product_name}}</div>
					</div>
				</div>
			</div>
			<div class="center">
				<el-pagination background layout="prev, pager, next" @size-change="sizeChange"
					@current-change="currentChange" :total="total">
				</el-pagination>
			</div>
			<div class="center-min">
				<el-pagination :pager-count="5" small background layout="prev, pager, next" @size-change="sizeChange"
					@current-change="currentChange" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		picurl
	} from "../../api/request.js";
	import {
		getOfficialProducts,
		getOfficialImgByType
	} from '../../api/goods.js';
	export default {
		data() {
			return {
				picurl,
				page: 1,
				size: 8,
				banner: '',
				goodsList: [],
				total: 0
			}
		},
		methods: {
			sizeChange(val) {
				this.size = val;
				this.getOfficialProducts();
			},
			getOfficialImgByType() {
				getOfficialImgByType({
					type: 6
				}).then(res => {
					console.log(res);
					this.banner = res[0];
				})
			},
			// 页码发生改变
			currentChange(val) {
				this.page = val;
				this.getOfficialProducts();
			},
			getOfficialProducts() {
				getOfficialProducts({
					page: this.page,
					size: this.size,
				}).then(res => {
					this.goodsList = res.list;
					this.total = res.total
				})
			},
			detailData(item){
				sessionStorage.goods=JSON.stringify(item);
				this.$router.push('detail');
			}
		},
		created() {
			document.getElementById("app").scrollIntoView();
			this.getOfficialProducts();
			this.getOfficialImgByType()
		}
	}
</script>

<style scoped="scoped">
	.center {
		text-align: center;
	}

	.center-min {
		display: none;
		text-align: center;
	}

	.goods {
		display: flex;
		flex-wrap: wrap;
	}

	.item-name {
		height: 110px;
		font-size: 18px;
		padding: 30px 60px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.item-name div {
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		text-align: center;
	}

	/deep/::-webkit-scrollbar {
		display: none;
	}

	.item {
		width: 280px;
		background-color: #f0f0f0;
		margin-bottom: 60px;
		margin-right: 40px;
		height: 413px;
		cursor: pointer;
		box-sizing: border-box;
		position: relative;
	}

	.item:hover {
		background-color: #1CA167;
		color: #fff;
		box-sizing: border-box;
		box-shadow: 0px 0px 5px 5px #dcdcdc;
		transform: scale(1.1);
		transition: all .3s;
	}

	.item img {
		width: 100%;
		background-color: #FFE2E2;
		height: 300px;
	}

	.content {
		position: relative;
		width: 1280px;
		left: 50%;
		margin-left: -640px;
		margin-bottom: 40px;
	}

	[v-cloak] {
		display: none;
	}

	.title {
		padding: 100px 0 60px 0;
		font-size: 36px;
		font-weight: bold;
		text-align: center;
		color: #333;
	}

	.banner {
		width: 100%;
		height: 67vh;
	}

	.banner img {
		display: block;
		width: 100%;
		height: 100%;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #1CA167;
	}

	.small{
		display: none;
	}
	@media (max-width: 500px) {
		.small{
			display: block;
		}
		.big{
			display: none;
		}
		.banner {
			height: auto;
		}

		.content {
			width: 100%;
			left: 0;
			margin-left: 0;
			box-sizing: border-box;
			margin-bottom: 16px;
		}

		.title {
			font-size: 16px;
			padding-top: 16px;
			padding-bottom: 12px;
		}

		.center-min {
			display: block;
		}

		.center {
			display: none;
		}

		.goods {
			box-sizing: border-box;
			padding: 0 12px;
			justify-content: space-between;
		}

		.item {
			width: 49%;
			margin-right: 0;
			height: auto;
			border-radius: 8px;
			overflow: hidden;
			margin-bottom: 12px;
		}

		.item img {
			height: auto;
		}

		.item-name {
			padding: 12px;
			height: 60px;
			font-size: 14px;
		}
	}
</style>
