<template>
	<div id="app" v-cloak>
		<div class="goods">
			<div class="back">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item>首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/brand' }">品牌</el-breadcrumb-item>
					<el-breadcrumb-item>商品详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<img :src="picurl + 'product/product_' + goods.product_id + '/' +goods.product_pic" alt="">
			<div class="name">{{goods.product_name}}</div>
			<div class="title">{{goods.product_title}}</div>
			<div class="info-big" v-for="item in parameters">{{item.parameter_name}}:<span>{{item.parameter_content}}</span></div>
		</div>
		<div class="info" v-for="item in parameters">{{item.parameter_name}}:<span>{{item.parameter_content}}</span></div>
		<div class="img-box">
			<img v-for="item in proPics" :src="picurl+'product/product_'+item.product_id+'/'+item.pic_addr" alt="">
		</div>
	</div>
</template>

<script>
	import {
		picurl
	} from '../../api/request.js';
	import {
		infoGoods
	} from '../../api/detail.js';
	export default{
		data(){
			return{
				goods:'',
				picurl,
				proPics:'',
				parameters:''
			}
		},
		methods:{
			infoGoods(){
				infoGoods({
					product_id: this.goods.product_id,
					shop_id: 1,
					user_id: 591
				}).then(res=>{
					console.log(res);
					this.proPics=res.proPics;
					this.parameters=res.parameters;
				})
			}
		},
		created() {
			document.getElementById("app").scrollIntoView();
			this.goods=JSON.parse(sessionStorage.goods);
			console.log(this.goods);
			this.infoGoods();
		}
	}
</script>

<style scoped="scoped">
	.back{
		left: 0;
		top: 6px;	
		position: absolute;
		font-size:18px;
	}
	.info{
		display: none;
	}
	.info-big{
		font-size: 16px;
		padding-top:4px;
		line-height: 32px;
	}
	.info-big span{
		padding-left: 24px;
	}
	.img-box{
		width: 1280px;
		position: relative;
		left: 50%;
		margin-left: -640px;
	}
	.img-box img{
		width: 100%;
		display: block;
	}
	.date{
		font-size: 18px;
		padding-top: 20px;
	}
	.title{
		padding-top: 36px;
		font-size: 20px;
		color: #1d3e97;
		margin-bottom: 32px;
	}
	.name{
		padding-top: 50px;
		font-size: 36px;
		font-weight: bold;
		color: #1d3e97;
	}
	.goods{
		position: relative;
		padding-left: 500px;
		width: 1280px;
		left: 50%;
		margin-left: -640px;
		height: 550px;
	}
	.goods img{
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0;
		top: 50px;
	}
	[v-cloak] {
		display: none;
	}
	@media (max-width:500px){
		.info-big{
			display: none;
		}
		.info{
			display: block;
			padding: 6px 12px;
			font-size: 14px;
			color: #333;
		}
		.info span{
			padding-left: 14px;
		}
		.back{
			position: absolute;
			left: 12px;
			top: 4px;
		}
		.img-box{
			width: 100%!important;
			left: 0!important;
			margin-left: 0!important;
		}
		.goods{
			width: 100%;
			position: relative;
			padding-left: 116px;
			left: 0!important;
			margin-left: 0!important;
			height: 130px;
			padding-right: 12px;
		}
		.goods img{
			width: 90px;
			height: 90px;
			left: 12px;
			border-radius: 8px ;
			top: 32px;
		}
		.name{
			font-size: 14px;
			font-weight: bold;
			padding-top: 36px;
			line-height: 14px;
		}
		.title{
			font-size: 14px;
			padding-top: 38px;
			line-height: 14px;
			 text-overflow: -o-ellipsis-lastline;
			  overflow: hidden;
			  text-overflow: ellipsis;
			  display: -webkit-box;
			  -webkit-line-clamp: 2;
			  line-clamp: 2;
			  -webkit-box-orient: vertical;
		}
		.date{
			padding-top: 6px;
			font-size: 12px;
			display: none;
		}
	}
</style>
