<template>
	<div id="app" v-cloak>
		<div class="banner big">
			<img v-if="banner.officialImgName" :src="picurl + 'official/' +banner.officialImgName" alt="">
			<img v-else src="../../../public/images/join_us.png" alt="">
		</div>
		<div class="banner small">
			<img src="../../../public/images/join-small.jpg" alt="">
		</div>
		<div class="content">
			<div class="title">社会招聘</div>
			<div class="card">
				<div class="card-title" :class="show?'green':''">电商运营<div class="icon" @click="show=!show"><i
							:class="show?'el-icon-remove':'el-icon-circle-plus'"></i></div>
				</div>
				<div class="card-cont" :class="show?'block':'none'">
					<div>
						<div>
							<div>岗位职责：</div>
							<div>1、负责维护电商平台和客户关系；</div>
							<div>2、负责优化和提高重点店铺运营能力和销售额；</div>
							<div>3、负责执行与配合相关营销活动，店铺的促销活动方案策划、上报与执行；</div>
							<div>4、负责收集市场和行业信息，实时监控、分析总结竞争对手、产品等信息，提供有效应对方案。</div>
						</div>
						<div>
							<div>任职要求：</div>
							<div>1、具备2年以上电商运营职经验、有分销开发经验者优先</div>
							<div>2、有上进心，爱岗敬业 ，性格温和，有耐心 </div>
							<div>3、有较强团队合作意识。</div>
							<div>4、负责收集市场和行业信息，实时监控、分析总结竞争对手、产品等信息，提供有效应对方案。</div>
						</div>
						<div>
							<div>*应聘简历请发送至：zhaopin@mg-pen.com（应聘职位请注明期望工作地点）</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		picurl
	} from '../../api/request.js';
	import {
		getOfficialImgByType
	} from "../../api/goods.js";
	export default {
		data() {
			return {
				show: true,
				picurl,
				banner:''
			}
		},
		methods: {
			getOfficialImgByType(){
				getOfficialImgByType({
					type:7
				}).then(res=>{
					console.log(res);
					this.banner=res[0];
				})
			},
		},
		created() {
			document.getElementById("app").scrollIntoView();
			this.getOfficialImgByType()
		}
	}
</script>

<style scoped="scoped">
	.block {
		height: 410px;
	}

	.none {
		height: 0;
		padding: 0 !important;
		margin: 0 !important;
		border-top: 0px solid #fff !important;
	}

	.height-doing {
		animation: first 0.5s;
	}

	.height-no {
		animation: myfirst 0.5s;
	}

	@-webkit-keyframes myfirst {
		0% {
			margin-top: 0;
		}

		100% {
			margin-top: 0px;
		}
	}

	@-webkit-keyframes myfirst {
		0% {
			margin-top: 0;
		}

		100% {
			margin-top: -40px;
		}
	}

	.card-cont {
		transition: all 1s;
		font-size: 18px;
		color: #666;
		line-height: 1.8;
		padding-top: 26px;
		border-top: 1px solid #dcdcdc;
		margin-top: 30px;
		overflow: hidden;
	}

	.icon {
		position: absolute;
		right: 0;
		top: -6px;
		font-size: 32px;
		color: #1CA167;
		cursor: pointer;
	}

	.card-title {
		font-size: 24px;
		position: relative;
		color: #333;
	}

	.card {
		border: 1px solid #1CA167;
		position: relative;
		padding: 30px 44px 30px 60px;
		margin-bottom: 30px;
	}

	.title {
		padding: 100px 0 60px 0;
		font-size: 36px;
		color: #333;
		font-weight: bold;
		text-align: center;
	}

	.content {
		position: relative;
		width: 1280px;
		left: 50%;
		margin-left: -640px;
		min-height: 200px;
		margin-bottom: 100px;
	}

	[v-cloak] {
		display: none;
	}

	.banner {
		width: 100%;
	}

	.banner img {
		display: block;
		width: 100%;
		height: 100%;
	}

	/deep/ .green {
		color: #1CA167 !important;
	}
	.small{
		display: none;
	}
	@media (max-width: 500px) {
		.small{
			display: block;
		}
		.big{
			display: none;
		}
		.content{
			padding: 0 12px;
			left: 0;
			margin-left: 0;
			width: 100%;
			box-sizing: border-box;
			margin-bottom: 12px;
			min-height: auto;
		}
		.title{
			font-size: 16px;
			padding-top: 16px;
			padding-bottom: 12px;
		}
		.card{
			padding: 12px;
			border-radius: 8px;
		}
		.card-title{
			font-size: 14px;
		}
		.icon{
			font-size: 18px;
			top: -2px;
		}
		.card-cont{
			font-size: 14px;
			padding-top: 12px;
			margin-top: 12px;
		}
	}
</style>
