<template>
	<div id="app" v-cloak>
		<div class="banner big">
			<img v-if="banner.officialImgName" :src="picurl + 'official/' +banner.officialImgName" alt="">
			<img v-else src="../../../public/images/gril.png" alt="">
		</div>
		<div class="banner small">
			<img src="../../../public/images/health-small.jpg" alt="">
		</div>
		<div class="content">
			<div class="title">健康专栏</div>
			<div class="card" v-for="(item,index) in articleList" @click="articleData(index,item)">
				<img class="img" :src="picurl + 'topic/cover/' + item.cover" alt="">
				<div>{{item.topic_name}}</div>
				<div>{{item.topic_date.split(' ')[0]}}<span>|</span>{{item.topic_type}}</div>
			</div>
			<div class="center">
				<el-pagination background layout="prev, pager, next" @size-change="sizeChange"
					@current-change="currentChange" :total="total">
				</el-pagination>
			</div>
			<div class="min-center">
				<el-pagination :pager-count="5" small background layout="prev, pager, next" @size-change="sizeChange"
					@current-change="currentChange" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		picurl
	} from "../../api/request.js";
	import {
		getOfficialTopic,
		getOfficialImgByType
	} from '../../api/goods.js';
	export default {
		data() {
			return {
				picurl,
				page: 1,
				size: 4,
				banner:'',
				articleList: [],
				total: 0
			}
		},
		methods: {
			articleData(index, item) {
				var rank = (this.page - 1) * 4 + (index + 1);
				sessionStorage.rank = rank;
				sessionStorage.article = JSON.stringify(item);
				this.$router.push('article');
			},
			getOfficialImgByType(){
				getOfficialImgByType({
					type:5
				}).then(res=>{
					console.log(res);
					this.banner=res[0];
				})
			},
			sizeChange(val) {
				this.size = val;
				this.getOfficialTopic();
			},
			// 页码发生改变
			currentChange(val) {
				this.page = val;
				this.getOfficialTopic();
			},
			getOfficialTopic() {
				getOfficialTopic({
					page: this.page,
					size: this.size,
				}).then(res => {
					this.articleList = res.list;
					this.total = res.total
				})
			}
		},
		created() {
			document.getElementById("app").scrollIntoView();
			this.getOfficialTopic();
			this.getOfficialImgByType()
		}
	}
</script>

<style scoped="scoped">
	.min-center{
		display: none;
	}
	/deep/ p {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin: 0;
		text-align: left !important;
	}

	.center {
		text-align: center;
	}

	.card>div:nth-child(4) {
		font-size: 14px;
		color: #999;
		padding-top: 54px;
	}

	.card>div:nth-child(4) span {
		padding: 0 12px;
	}

	.card>div:nth-child(2) {
		padding-top: 72px;
		font-weight: bold;
		color: #333;
		font-size: 18px;
		padding-bottom: 22px;
	}

	.card>div:nth-child(3) {
		font-size: 14px;
		color: #666;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		height: 18px;
		margin-top: 60px;
	}

	.card {
		position: relative;
		height: 260px;
		padding-left: 396px;
		background-color: #fff;
		margin-bottom: 30px;
		padding-right: 30px;
		box-shadow: 0 2px 2px 2px #dcdcdc;
		border-radius: 8px;
		background-color: #f9f9f9;
		cursor: pointer;
	}

	.img {
		position: absolute;
		top: 30px;
		left: 30px;
		width: 336px;
		height: 200px;
		display: block;
	}

	.content {
		position: relative;
		width: 1280px;
		left: 50%;
		margin-left: -640px;
		margin-bottom: 40px;
	}

	[v-cloak] {
		display: none;
	}

	.title {
		padding: 100px 0 60px 0;
		font-size: 36px;
		font-weight: bold;
		text-align: center;
		color: #333;
	}

	.banner {
		width: 100%;
	}

	.banner img {
		display: block;
		width: 100%;
		height: 100%;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #1CA167;
	}
	.small{
		display: none;
	}
	@media (max-width: 500px) {
		.small{
			display: block;
		}
		.big{
			display: none;
		}
		.title{
			font-size: 16px;
			padding-top: 16px;
			padding-bottom: 12px;
		}
		.min-center{
			display: block;
			text-align: center;
		}
		.center{
			display: none;
		}
		.content{
			padding: 0 12px;
			box-sizing: border-box;
			width: 100%;
			left: 0;
			margin-left: 0;
			margin-bottom: 16px;
		}
		.card{
			padding-left: 172px;
			height: 90px;
			padding-right: 12px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin-bottom: 16px;
		}
		.card>div:nth-child(2){
			padding-top: 12px;
			padding-bottom: 0;
			font-size: 14px;
		}
		.card>div:nth-child(3){
			margin-top: 0;
			padding-bottom: 12px;
			height: auto;
		}
		.card img{
			width: 146px;
			height: 66px;
			top: 12px;
			border-radius: 8px;
			left: 12px;
		}
	}
</style>
