<template>
	<div id="app" v-cloak>
		<div class="banner big">
			<img v-if="banner.officialImgName" :src="picurl + 'official/' +banner.officialImgName" alt="">
			<img v-else src="../../../public/images/footer_doing.png" alt="">
		</div>
		<div class="banner small">
			<img src="../../../public/images/new-small.jpg" alt="">
		</div>
		<div class="content">
			<div class="title">最新动态</div>
			<div class="goods">
				<img @click="show=!show" src="../../../public/images/dynamic1.jpg" alt="">
				<img @click="show=!show" src="../../../public/images/dynamic2.jpg" alt="">
				<img @click="show=!show" src="../../../public/images/dynamic3.jpg" alt="">
				<img @click="show=!show" src="../../../public/images/dynamic4.jpg" alt="">
			</div>
		</div>
		<div class="posf" v-if="show">
			<div class="close" @click="show=false"><i class="el-icon-circle-close"></i></div>
			<img src="../../../public/images/app_download.png" alt="">
		</div>
	</div>
</template>

<script>
	import {
		picurl
	} from '../../api/request.js';
	import {
		getOfficialImgByType
	} from "../../api/goods.js";
	export default {
		data() {
			return {
				show:false,
				picurl,
				banner:''
			}
		},
		methods:{
			getOfficialImgByType(){
				getOfficialImgByType({
					type:4
				}).then(res=>{
					console.log(res);
					this.banner=res[0];
				})
			},
		},
		created() {
			document.getElementById("app").scrollIntoView();
			this.getOfficialImgByType()
		}
	}
</script>

<style scoped="scoped">
	.close{
		position: absolute;
		right: 50px;
		top: 120px;
		color: #fff;
		font-size: 40px;
	}
	.posf{
		width: 100%;
		height: 100%;
		position: fixed;
		background: rgba(0,0,0,0.5);
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	[v-cloak] {
		display: none;
	}

	.content {
		margin-bottom: 74px;
	}

	.goods {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		position: relative;
		width: 1280px;
		left: 50%;
		margin-left: -640px;
	}

	.goods img {
		width: 630px;
		height: 320px;
		margin-bottom: 26px;
	}

	.title {
		padding: 100px 0 60px 0;
		font-size: 36px;
		font-weight: bold;
		text-align: center;
		color: #333;
	}

	.banner {
		width: 100%;
	}

	.banner img {
		display: block;
		width: 100%;
		height: 100%;
	}
	.small{
		display: none;
	}
	@media (max-width: 500px) {
		.close{
			position: absolute;
			right: 6vw;
			top: 8vh;
			color: #fff;
			font-size: 6vw;
		}
		.small{
			display: block;
		}
		.big{
			display: none;
		}
		.title{
			font-size: 16px;
			padding-top: 16px;
			padding-bottom: 12px;
		}
		.goods{
			width: 100%!important;
			left: 0!important;
			margin-left: 0!important;
			box-sizing: border-box;
			padding: 0 12px;
		}
		.goods img{
			margin-bottom: 12px;
			border-radius: 8px;
			width: 100%!important;
			height: auto;
		}
		.content{
			margin-bottom: 0;
		}
	}
</style>
