import {
	request
} from "./resquestOld.js";
import qs from "qs";

export function infoGoods(obj) { //商品详情
	return request({
		url: "shangpinxiangqingShopAction.action",
		method: "post",
		data: qs.stringify(obj)
	});
}