<template>
	<div id="app" v-cloak>
		<div class="banner big">
			<img v-if="banner.officialImgName" :src="picurl + 'official/' +banner.officialImgName" alt="">
			<img v-else src="../../../public/images/about_culture.png" alt="">
		</div>
		<div class="banner small">
			<img src="../../../public/images/culture-small.jpg" alt="">
		</div>
		<div class="content">
			<div class="title">企业文化</div>
			<div class="flex">
				<div>
					<div class="box">
						<div>企业愿景</div>
						<div class="ccc">成为世界品牌</div>
					</div>
					<img class="culture_img" src="../../../public/images/culture_img1.png" alt="">
				</div>
				<div>
					<div class="box">
						<div>企业宗旨</div>
						<div class="ccc">诚信立足,创新致远</div>
					</div>
					<img class="culture_img" src="../../../public/images/culture_img2.png" alt="">
				</div>
				<div>
					<div class="box">
						<div>企业价值观</div>
						<div class="ccc">为用户,更卓越</div>
					</div>
					<img class="culture_img" src="../../../public/images/culture_img3.png" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		picurl
	} from '../../api/request.js';
	import {
		getOfficialImgByType
	} from "../../api/goods.js";
	export default {
		data() {
			return {
				picurl,
				banner:''
			}
		},
		methods:{
			getOfficialImgByType(){
				getOfficialImgByType({
					type:3
				}).then(res=>{
					console.log(res);
					this.banner=res[0];
				})
			},
		},
		created() {
			document.getElementById("app").scrollIntoView();
			document.querySelector('body').setAttribute('style', 'background-color:#fff');
			this.getOfficialImgByType()
		}
	}
</script>

<style scoped="scoped">
	.ccc {
		margin-top: 8px;
		font-weight: 400;
	}

	.box {
		height: 144px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 50px;
		font-size: 24px;
		font-weight: bold;
	}

	.flex {
		display: flex;
		justify-content: space-between;
		width: 1280px;
		position: relative;
		left: 50%;
		margin-left: -640px;
	}

	.flex>div {
		background-color: #f7f7f7;
		cursor: pointer;
		box-sizing: border-box;
		border: 1px solid #fff;
	}

	.culture_img {
		width: 400px;
		height: 356px;
		display: block;
	}

	[v-cloak] {
		display: none;
	}

	.title {
		font-size: 36px;
		padding: 100px 0 60px 0;
		font-weight: bold;
		text-align: center;
	}

	.banner img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.banner {
		width: 100%;
	}

	/deep/ .flex>div:hover {
		background-color: #1CA167;
		color: #fff !important;
		border: #1CA167 1px solid;
	}
	.content {
		margin-bottom: 100px;
	}
	.small{
		display: none;
	}
	@media (max-width: 500px) {
		.small{
			display: block;
		}
		.big{
			display: none;
		}
		.title{
			font-size: 16px;
			padding-top: 16px;
			padding-bottom: 12px;
		}
		.flex{
			width: 100%!important;
			display: flex;
			flex-direction: column;
			left: 0!important;
			margin-left: 0!important;
			box-sizing: border-box;
			padding: 0 12px;
		}
		.flex>div{
			flex: 1;
			margin-bottom: 12px;
			border-radius: 8px;
			overflow: hidden;
		}
		.culture_img{
			width: 100%;
		}
		.content{
			margin-bottom: 0;
		}
		.box{
			font-size: 14px;
			height: 80px;
		}
	}
</style>
