import axios from "axios";
const PRODUCT_URL = "http://www.ccclkj.cn/zhensuo/sys/";

const MOCK_URL = "http://www.ccclkj.cn/zhensuo/sys/";
export const picurl = 'http://www.ccclkj.cn/zhibanzhang/resource/';
export const baseurl =
	process.env.NODE_ENV === "production" ? PRODUCT_URL : MOCK_URL;

export function request(config) {
	const instance = axios.create({
		baseURL: baseurl
	});
	instance.interceptors.request.use(
		config => {
			return config;
		},
		err => {
			console.log(err);
		}
	);

	instance.interceptors.response.use(
		response => {
			let res = response.data;
			console.log(res);
			return res;
		},
		err => {
			console.log(err);
		}
	);
	return instance(config);
}
