<template>
	<div id="app" v-cloak>
		<div class="banner big">
			<img v-if="banner.officialImgName" :src="picurl + 'official/' +banner.officialImgName" alt="">
			<img v-else src="../../../public/images/about_us.png" alt="">
		</div>
		<div class="banner small">
			<img src="../../../public/images/about-small.jpg" alt="">
		</div>
		<div class="content">
			<div class="title pt100">公司介绍</div>
			<div class="p60">
				<div style="padding: 0px 40px 0 0;width: 580px;">
					<div>
						诚赉健康产业（吉林）有限公司是一家助力社会医疗机构数字化升级的企业，创立于2021年6月，坚持以“创造企业价值
						回馈百姓生活”的企业宗旨，为海量社会医疗机构提供优质的互联网产品与服务，让百姓享受健康与实惠。目前，诚赉健康产业旗下拥有值班长APP和诚赉大健康两大业务板块，覆盖实体店、智慧电商零售、数字化门店、自助式营销、产品销售推广等多种服务，帮助用户借助互联网技术的力量，更高效地服务于民。
					</div>
					<!-- <div>1.使命：与企业共建百年品牌</div>
					<div>2.价值观：爱祖国 爱人民 爱企业</div>
					<div>3.合作企业</div>
					<div style="text-indent: 4em;">(1)晨光生物科技集团股份有限公司</div>
					<div style="text-indent: 4em;">(2)中山长曜医疗器材有限公司</div>
					<div style="text-indent: 4em;">(3)坚朗（KIN LONG）</div>
					<div style="text-indent: 4em;">(4)诚赉电子商务有限公司</div>
					<div>4.企业文化、宗旨：创造企业价值  回馈百姓生活</div> -->
				</div>
				<div class="img-right" style="height: 546px;">
					<img src="../../../public/images/office.png" alt="">
				</div>
			</div>
		</div>
		<div class="center-box">
			<div class="center-in-box">
				<div>
					<img src="../../../public/images/about_icon1.png" alt="">
					<div>
						<div class="font18">愿景与使命</div>
						<div class="font16">建立中国百城万店连锁品牌</div>
						<div class="font16">与企业共建百年品牌</div>
					</div>
				</div>
				<div>
					<img src="../../../public/images/about_icon2.png" alt="">
					<div>
						<div class="font18">价值观</div>
						<div class="font16">爱祖国、爱人民、爱企业</div>
					</div>
				</div>
			</div>
		</div>
		<div class="content">
			<div class="title new-title" style="margin-bottom: 120px;">合作企业</div>
			<div class="flex">
				<div class="info">
					<div class="info-title">晨光生物科技集团股份有限公司</div>
					<div class="info-text">
						诚赉健康产业（吉林）有限公司是一家助力社会医疗机构数字化升级的企业，创立于2021年6月，坚持以“创造企业价值
						回馈百姓生活”的企业宗旨，为海量社会医疗机构提供优质的互联网产品与服务，让百姓享受健康与实惠。目前，诚赉健康产业旗下拥有值班长APP和诚赉大健康两大业务板块，覆盖实体店、智慧电商零售、数字化门店、自助式营销、产品销售推广等多种服务，帮助用户借助互联网技术的力量，更高效地服务于民。
					</div>
				</div>
				<div class="img-right">
					<img src="../../../public/images/company.png" alt="">
				</div>
			</div>
			<div class="flex">
				<div class="img-left">
					<img src="../../../public/images/company.png" alt="">
				</div>
				<div class="info">
					<div class="info-title">中山长曜医疗器材有限公司</div>
					<div class="info-text">
						作为全球最大的文具制造商之一，晨光文具是一家整合创意价值与服务优势的综合文具供应商，致力于让学习和工作更快乐、更高效。产品涵盖书写工具、学生文具、办公文具及其他相关产品。公司于2015年正式在上交所挂牌上市，股票代码603899。
					</div>
				</div>
			</div>
			<div class="flex">
				<div class="info">
					<div class="info-title">坚朗（KIN LONG）</div>
					<div class="info-text">
						诚赉健康产业（吉林）有限公司是一家助力社会医疗机构数字化升级的企业，创立于2021年6月，坚持以“创造企业价值
						回馈百姓生活”的企业宗旨，为海量社会医疗机构提供优质的互联网产品与服务，让百姓享受健康与实惠。目前，诚赉健康产业旗下拥有值班长APP和诚赉大健康两大业务板块，覆盖实体店、智慧电商零售、数字化门店、自助式营销、产品销售推广等多种服务，帮助用户借助互联网技术的力量，更高效地服务于民。
					</div>
				</div>
				<div class="img-right">
					<img src="../../../public/images/company.png" alt="">
				</div>
			</div>
			<div class="flex">
				<div class="img-left">
					<img src="../../../public/images/company.png" alt="">
				</div>
				<div class="info">
					<div class="info-title">诚赉电子商务有限公司</div>
					<div class="info-text">
						作为全球最大的文具制造商之一，晨光文具是一家整合创意价值与服务优势的综合文具供应商，致力于让学习和工作更快乐、更高效。产品涵盖书写工具、学生文具、办公文具及其他相关产品。公司于2015年正式在上交所挂牌上市，股票代码603899。
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		picurl
	} from '../../api/request.js';
	import {
		getOfficialImgByType
	} from "../../api/goods.js";
	export default {
		data() {
			return {
				banner: '',
				picurl
			}
		},
		methods: {
			getOfficialImgByType() {
				getOfficialImgByType({
					type: 2
				}).then(res => {
					console.log(res);
					this.banner = res[0];
				})
			},
		},
		created() {
			document.getElementById("app").scrollIntoView();
			this.getOfficialImgByType()
		}
	}
</script>

<style scoped="scoped">
	.info-text {
		font-size: 18px;
		color: #666;
		line-height: 34px;
		margin-top: 12px;
		text-indent: 2em;
	}

	.info-title {
		font-size: 20px;
		font-weight: bold;
	}

	.flex {
		display: flex;
		margin-bottom: 80px;
	}

	.info {
		width: 580px;
	}

	.center-in-box img {
		margin-right: 30px;
	}

	.font18 {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 26px;
	}

	.font16 {
		font-size: 14px;
		color: #666;
		margin-top: 6px;
	}

	.center-in-box {
		position: absolute;
		width: 1280px;
		height: 100%;
		left: 50%;
		margin-left: -640px;
		top: 0;
		display: flex;
	}

	.center-in-box>div {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.center-box {
		width: 100%;
		height: 200px;
		background: #F5FBF8;
		position: relative;
		margin: 100px 0;
	}

	.img-left,
	.img-right {
		position: relative;
		height: 640px;
		width: 700px;
	}

	.img-left::before {
		position: absolute;
		width: 210px;
		height: 174px;
		background: #1CA167;
		content: '';
		left: 0;
		top: 0;
	}

	.img-right::before {
		position: absolute;
		width: 210px;
		height: 174px;
		background: #1CA167;
		right: 0;
		content: '';
		top: 0;
	}

	.img-left::after {
		position: absolute;
		width: 210px;
		height: 136px;
		background: #1CA167;
		content: '';
		right: 0;
		bottom: 7px;
		opacity: 0.5;
	}

	.img-right::after {
		position: absolute;
		width: 210px;
		height: 136px;
		background: #1CA167;
		content: '';
		left: 0;
		bottom: 7px;
		opacity: 0.5;
	}

	.img-right img {
		position: absolute;
		left: 46px;
		top: 60px;
		z-index: 10;
		display: block;
	}

	.img-left img {
		position: absolute;
		right: 46px;
		top: 60px;
		z-index: 10;
		display: block;
	}

	.p60 {
		font-size: 18px;
		color: #666;
		text-indent: 2em;
		display: flex;
		justify-content: space-between;
	}

	.p60>div>div {
		margin-bottom: 16px;
		line-height: 30px;
	}

	.content {
		width: 1280px;
		position: relative;
		left: 50%;
		margin-left: -640px;
	}

	.pt100 {
		padding-top: 100px;
		padding-bottom: 30px;
	}

	.title {
		font-size: 36px;
		font-weight: bold;
		color: #333;
		width: 100%;
		text-align: center;
	}

	[v-cloak] {
		display: none;
	}

	.banner {
		width: 100%;
	}

	.banner img {
		display: block;
		width: 100%;
		height: 100%;
	}

	.small{
		display: none;
	}
	@media (max-width: 500px) {
		.small{
			display: block;
		}
		.big{
			display: none;
		}
		.content {
			width: 100% !important;
			left: 0;
			margin-left: 0;
		}

		.pt100 {
			padding-top: 16px;
			font-size: 16px;
			padding-bottom: 12px;
		}

		.p60 {
			box-sizing: border-box;
			padding: 0 12px;
			display: flex;
			flex-direction: column;
			font-size: 14px;
		}

		.p60>div {
			padding: 0px !important;
			flex: 1 !important;
			width: auto !important;
			height: auto !important;
			position: relative !important;
		}

		.p60>div>div {
			margin-bottom: 0 !important;
			line-height: 24px !important;
			position: relative !important;
		}

		.img-right img {
			position: relative !important;
			width: 80% !important;
			left: 10% !important;
			top: 0 !important;
		}

		.p60>div:nth-child(2) {
			margin-top: 16px;
			padding-top: 40px !important;
			padding-bottom: 20px !important;
		}

		.img-right::after {
			bottom: 0 !important;
		}

		/deep/ .img-right {
			margin-top: 16px;
			padding-top: 40px !important;
			padding-bottom: 20px !important;
		}

		.img-left img {
			position: relative !important;
			width: 80% !important;
			left: 10% !important;
			margin-left: 0 !important;
			top: 0;
			right: 0;
		}

		.img-left::before {
			top: -32px !important;
		}

		.img-left::after {
			bottom: -16px !important;
		}

		.center-box {
			display: none;
		}

		.flex {
			display: flex;
			flex-direction: column;
			padding: 0 12px;
		}

		.flex:last-child {
			margin-bottom: 12px;
		}

		.info-title {
			padding-top: 32px;
			padding-bottom: 10px;
			text-align: center;
			font-size: 14px;
		}

		.flex>div {
			flex: 1;
			width: auto;
			height: auto;
		}
		/deep/.flex:nth-child(3){
			margin-bottom: 0!important;
		}
		.info-text {
			margin-top: 0;
			line-height: 22px;
			font-size: 14px;
		}
		.new-title{
			font-size: 18px;
			margin-bottom: 0px!important;
			padding-top: 36px;
		}
	}
</style>
