<template>
	<div id="app" v-cloak>
		<img class="banner" src="../../../public/images/dwonload.png" alt="">
		<img class="small" @click="show=true" src="../../../public/images/app-down.jpg" alt="">
		<div class="posf" v-if="show">
			<div class="close" @click="show=false"><i class="el-icon-circle-close"></i></div>
			<img src="../../../public/images/app_download.png" alt="">
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				show:false,
			}
		},
		created() {
			document.getElementById("app").scrollIntoView();
		}
	}
</script>

<style scoped="scoped">
	.close{
		position: absolute;
		right: 50px;
		top: 120px;
		color: #fff;
		font-size: 40px;
	}
	.posf{
		width: 100%;
		height: 100%;
		position: fixed;
		background: rgba(0,0,0,0.5);
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.banner {
		width: 100%;
		display: block;
	}
	.small{
		display: none;
	}
	[v-cloak] {
		display: none;
	}
	@media (max-width:500px) {
		.banner{
			display: none;
		}
		.small{
			display: block;
			width: 100%;
			display: block;
		}
		.close{
			position: absolute;
			right: 6vw;
			top: 8vh;
			color: #fff;
			font-size: 6vw;
		}
	}
</style>
